import { useScreenSize } from '@shopopop/react-hooks';
import { DatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { boDateFromString, getDateReferences } from '../../../../utils/src/date/date';
import { DateRangeValues } from '../../interfaces/DateRangesValues';
import DateSelector from './DateSelector';

const { RangePicker } = DatePicker;

interface DateRangeSelectorProps {
  submitForm: (values: DateRangeValues) => void;
  startDateParams: string;
  endDateParams: string;
}

type DayJsTimeSlot = [Dayjs, Dayjs];

const DateRangeSelector = forwardRef(({ submitForm, startDateParams, endDateParams }: DateRangeSelectorProps, ref) => {
  const { t } = useTranslation();
  const { mobileMode } = useScreenSize();
  const [form] = Form.useForm();
  const location = useLocation();

  const [selectedRange, setSelectedRange] = useState<DayJsTimeSlot>([dayjs().startOf('day'), dayjs().endOf('day')]);

  const sameDay = selectedRange[0].isSame(selectedRange[1], 'day');

  const dateOptions = Object.entries(getDateReferences(['YESTERDAY', 'TODAY', 'TOMORROW'])).map(([key, value]) => {
    return {
      value: value,
      labelKey: key,
      date: boDateFromString(value),
    };
  });

  useImperativeHandle(ref, () => ({
    submit: () => {
      form.submit();
    },
    reset: () => {
      form.resetFields();
    },
  }));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startDate = params.get(startDateParams);
    const endDate = params.get(endDateParams);
    if (startDate && endDate) {
      setSelectedRange([dayjs(startDate), dayjs(endDate)]);
    } else setSelectedRange([dayjs().startOf('day'), dayjs().endOf('day')]);
  }, [location.search]);

  useEffect(() => {
    form.setFieldsValue({
      deliveryDate: sameDay ? selectedRange[0].toISOString() : '',
      startDate: selectedRange[0],
      endDate: selectedRange[1],
      timeRange: selectedRange,
    });
  }, [selectedRange, form]);

  const handleDateChange = (value: string) => {
    const newRange: DayJsTimeSlot = [dayjs(value).startOf('day'), dayjs(value).endOf('day')];
    setSelectedRange(newRange);
    form.setFieldsValue({
      deliveryDate: newRange[0].toISOString(),
      startDate: newRange[0],
      endDate: newRange[1],
      timeRange: newRange,
    });
  };

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null]) => {
    if (dates[0] && dates[1]) {
      const newRange: DayJsTimeSlot = [dayjs(dates[0]), dayjs(dates[1]).endOf('day')];
      setSelectedRange(newRange);
      form.setFieldsValue({
        deliveryDate: sameDay ? newRange[0].toISOString() : '',
        startDate: newRange[0],
        endDate: newRange[1],
        timeRange: newRange,
      });
    }
  };

  const validateEndDate = (_: unknown, value: Dayjs) => {
    const startDate = form.getFieldValue('startDate');
    if (value?.isBefore(startDate)) {
      return Promise.reject(new Error(t('END_DATE_MUST_BE_AFTER_START_DATE')));
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '100%' }}
      initialValues={{
        deliveryDate: sameDay ? selectedRange[0].toISOString() : '',
        startDate: selectedRange[0],
        endDate: selectedRange[1],
        timeRange: selectedRange,
      }}
      onFinish={(values) => {
        submitForm(values);
      }}
    >
      <Form.Item name="deliveryDate">
        <DateSelector dateOptions={dateOptions} handleDateChange={handleDateChange} initialValue={sameDay ? selectedRange[0].toISOString() : ''} />
      </Form.Item>
      {mobileMode ? (
        <>
          <Form.Item label={t('START_DATE')} name="startDate">
            <DatePicker
              style={{ width: '100%' }}
              format={'DD MMMM YYYY'}
              value={selectedRange[0]}
              onChange={(date) => {
                date && handleRangeChange([date, selectedRange[1]]);
              }}
              allowClear={false}
              minDate={dayjs().subtract(3, 'month').startOf('day')}
            />
          </Form.Item>
          <Form.Item label={t('END_DATE')} name="endDate" rules={[{ validator: validateEndDate }]}>
            <DatePicker
              style={{ width: '100%' }}
              format={'DD MMMM YYYY'}
              value={selectedRange[1]}
              onChange={(date) => {
                date && handleRangeChange([selectedRange[0], date]);
              }}
              allowClear={false}
              minDate={selectedRange[0]}
            />
          </Form.Item>
        </>
      ) : (
        <Form.Item label={t('CHOOSE_DATE_RANGE')} name="timeRange">
          <RangePicker
            style={{ width: '100%' }}
            format={'DD MMMM YYYY'}
            value={selectedRange}
            onChange={(values) => {
              values && handleRangeChange([values[0], values[1]]);
            }}
            allowClear={false}
            minDate={dayjs().subtract(3, 'month').startOf('day')}
          />
        </Form.Item>
      )}
    </Form>
  );
});

export default DateRangeSelector;
